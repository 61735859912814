<template>
  <div class="about-page">
    <r-container>
      <r-meta
        title="mallimin | about us"
        description="about us page"
        :url="$helper.url() + $route.path"
      ></r-meta>
      <div
        :class="`${$r.prefix}text-editor`"
        v-html="$helper.cleanXss(d)"
      ></div>
    </r-container>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data() {
    return {
      d: ""
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios.get("/home/settings/about_us").then(({ data }) => {
        this.d = data;
      });
    }
  }
};
</script>
