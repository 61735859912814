<template>
  <div
    class="template-user h-end"
    :class="{
      'menu-open': open
    }"
  >
    <r-card class="toolbar border-none d-flex v-center">
      <r-btn class="btn-menu" icon text @click.prevent="open = !open">
        <r-icon :class="{ 'mdi-menu': !open, 'mdi-close': open }"></r-icon>
      </r-btn>
      <router-link to="/">
        <img class="me-2" src="/storage/img/site/brand.jpeg?w=170&h=60" />
      </router-link>
      <r-avatar size="50" class="me-1">
        <r-img
          :src="
            '/' +
              $helper.ifHas(
                $r.store,
                'storage/img/site/avatar.jpg',
                'user',
                'info',
                'images',
                '0'
              )
          "
          alt="user profile"
          width="44"
          height="44"
        ></r-img>
      </r-avatar>
      <span
        v-if="$helper.ifHas($r.store, false, 'user', 'info', 'name')"
        >{{ $t(["welcome", [$r.store.user.info.name]]) }}</span
      >
      <r-spacer></r-spacer>
      <r-btn
        icon
        text
        :label="numMsg"
        label-class="color-error"
      >
        <r-icon class="mdi-bell-outline"></r-icon>
      </r-btn>
    </r-card>
    <div
      class="user-labels text-end"
      :class="{
        'user-labels-open': labels_open
      }"
    >
      <r-card class="overflow-x-auto labels-container h-end py-2">
        <r-card
          class="text-center mx-1 labels-item"
          v-for="(item, key) in labels"
          :key="key"
          @click.prevent="label_info(item._id)"
        >
          <r-img
            :src="item.image"
            :alt="item.name"
            width="100"
            height="100"
          ></r-img>
          <div class="mt-1">{{ item.name }}</div>
        </r-card>
      </r-card>
      <r-btn
        class="color-gradient-one-45 mx-2"
        icon
        @click.prevent="labels_open = !labels_open"
      >
        <r-icon
          :class="{
            'mdi-chevron-down': !labels_open,
            'mdi-chevron-up': labels_open
          }"
        ></r-icon>
      </r-btn>
    </div>
    <div class="menu-user">
      <div class="list" v-if="menu">
        <sideBar :items="menu" :key="menu_key"></sideBar>
      </div>

      <div class="hover-div" @click.prevent="open = false"></div>
    </div>
    <div class="router-div flex-grow-1">
      <router-view v-slot="{ Component }">
        <transition name="slide-start" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <r-modal v-model="show" bottom full-width>
      <r-card>
        <r-container>
          <r-progress-liner v-if="!info" color="color-one"></r-progress-liner>
          <r-row v-else>
            <r-col class="col-12 h-center">
              <r-img
                :src="'/' + info.images[0]"
                alt="label"
                width="200"
                height="200"
              ></r-img>
            </r-col>
            <r-col class="col-6 md-3">{{ $t("name") }}:</r-col>
            <r-col class="col-6 md-9">{{ info.name }}</r-col>
            <r-col class="col-6 md-3">{{ $t("children") }}:</r-col>
            <r-col class="col-6 md-9">{{ $n(info.children || 0) }}</r-col>
            <r-col class="col-6 md-3">{{ $t("volume") }}:</r-col>
            <r-col class="col-6 md-9">{{ $n(info.volume || 0) }}</r-col>
            <r-col class="col-6 md-3">{{ $t("label_required") }}:</r-col>
            <r-col class="col-6 md-9">
              <template v-if="info.label">
                <r-chip class="mx-1" v-for="(item, i) in info.label" :key="i">{{
                  item.name
                }}</r-chip>
              </template>
            </r-col>
            <r-col class="col-6 md-3">{{ $t("forever") }}:</r-col>
            <r-col class="col-6 md-9">{{
              $t(info.forever ? "yes" : "no")
            }}</r-col>
            <r-col class="col-6 md-3">{{ $t("gifts") }}:</r-col>
            <r-col class="col-6 md-9">
              <template v-if="info.gifts">
                <r-chip
                  @click.prevent="gift_info(item.value)"
                  class="mx-1"
                  v-for="(item, i) in info.gifts"
                  :key="i"
                  >{{ item.name }}</r-chip
                >
              </template>
            </r-col>
            <r-col class="col-6 md-3">{{ $t("description") }}:</r-col>
            <r-col class="col-6 md-9">
              <r-text-editor-preview
                :text="info['description']"
              ></r-text-editor-preview>
            </r-col>
            <r-col class="col-6 md-3">{{ $t("files") }}:</r-col>
            <r-col class="col-6 md-9">
              <template v-if="info.files">
                <r-btn
                  v-for="(file, i) in info.files"
                  :key="i"
                  class="color-info mx-1"
                  :href="'/' + file"
                  target="_blank"
                  >{{ info.name }}-{{ i + 1 }}</r-btn
                >
              </template>
            </r-col>
          </r-row>
        </r-container>
      </r-card>
    </r-modal>
    <r-modal v-model="show_gift">
      <r-card>
        <r-progress-liner v-if="!gift" color="color-one"></r-progress-liner>
        <r-container v-else>
          <r-row>
            <r-col class="col-12 text-center">
              <r-img
                :src="'/' + gift['images'][0]"
                auto-size
                :w-p-h="2"
                alt="gift"
              ></r-img>
            </r-col>
            <r-col class="col-12">
              {{ gift["name"] }}
            </r-col>
            <r-col class="col-12">
              {{ $t("price") }}: {{ $n(gift["price"]) }}
            </r-col>
            <r-col class="col-12">
              <r-text-editor-preview
                :text="gift['description']"
              ></r-text-editor-preview>
            </r-col>
          </r-row>
        </r-container>
      </r-card>
    </r-modal>
  </div>
</template>

<script>
import sideBar from "../components/sideBar";

export default {
  name: "user",
  components: { sideBar },
  data() {
    return {
      open: false,
      show: false,
      show_gift: false,
      gift: null,
      info: null,
      interval_id: null,
      labels_open: false,
      labels: [],
      numMsg: 0,
      menu: null,
      menu_key: 0
    };
  },
  created() {
    this.get();
    this.getLabels();
    this.getCount();
    this.$r.store.unit = "toman";
    this.menu = this.build_menu();
  },
  watch: {
    $route: function() {
      this.open = false;
    }
  },
  computed: {
    me() {
      return this.$helper.ifHas(this.$r.store, {}, "me");
    }
  },
  methods: {
    build_menu() {
      let res = [];
      res.push({
        name: "dashboard",
        icon: "mdi-desktop-mac-dashboard",
        to: { name: "dashboard" }
      });
      /*res.push({
        name: "store",
        icon: "mdi-store",
        to: { name: "categories" }
      });*/
      res.push({
        name: "vouchers",
        icon: "mdi-percent-outline",
        to: { name: "voucherCategories" }
      });
      res.push({
        name: "orders",
        icon: "mdi-basket-outline",
        childs: [
          { name: "orders", to: { name: "orders" } },
          { name: "orders_free", to: { name: "orders_free" } }
        ]
      });
      let childs = [{ name: "join_graph", to: { name: "joinGraph" } }];
      if (this.me.block === false) {
        childs = [
          { name: "graph", to: { name: "graph" } },
          { name: "payment", to: { name: "payment" } },
          { name: "active_state", to: { name: "activeState" } },
          { name: "invite_link", to: { name: "inviteLink" } },
          { name: "inactive_account", to: { name: "inactive" } },
          { name: "graph_history", to: { name: "graphHistory" } }
        ];
      }
      res.push({
        name: "graph",
        icon: "mdi-graph",
        childs: childs
      });
      if (this.me.active) {
        res.push({
          name: "financial",
          icon: "mdi-cash-multiple",
          childs: [
            { name: "payments", to: { name: "paymentsList" } },
            { name: "payment_gift", to: { name: "paymentGift" } },
            { name: "voucher_cash", to: { name: "voucherCash" } }
          ]
        });
      }
      res.push({
        name: "messages",
        icon: "mdi-message",
        childs: [
          { name: "messages", to: { name: "messages" } },
          /*{ name: "tickets", to: { name: "tickets" } }*/
        ]
      });
      res.push({ name: "default", type: "hr" });
      res.push({
        name: "settings",
        icon: "mdi-settings",
        childs: [
          { name: "profile", to: { name: "profile" } },
          { name: "settings", to: { name: "settings" } }
        ]
      });
      return res;
    },
    getCount() {
      this.$axios.get("/user/alert/count").then(({ data }) => {
        this.numMsg = data;
      });
    },
    gift_info(id) {
      this.show_gift = true;
      this.gift = null;
      this.$axios.get("user/gifts/" + id).then(
        ({ data }) => {
          if (data !== null) {
            this.gift = data;
          }
        },
        () => {
          this.show_gift = false;
        }
      );
    },
    label_info(id) {
      this.show = true;
      this.info = null;
      this.$axios.get("/user/labels/" + id).then(
        ({ data }) => {
          this.info = data;
        },
        () => {
          this.show = false;
        }
      );
    },
    get() {
      this.$axios.get("/user/graph/me").then(({ data }) => {
        this.$r.store["me"] = data;
        this.menu = this.build_menu();
        this.menu_key++;
      });
    },
    getLabels() {
      this.$axios.get("/user/labels").then(({ data }) => {
        this.labels = data;
      });
    }
  },
  beforeUnmount() {
    clearInterval(this.interval_id);
  }
};
</script>

<style lang="scss">
@import "~renusify/style/_include";

$menu-width: 300px;
.template-user {
  display: flex;
  flex-direction: row;
  position: relative;

  .toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: $toolbar-height;
    z-index: map_get($z-index, "medium");
  }
  .user-labels {
    position: absolute;
    left: 0;
    top: 0;
    z-index: map_get($z-index, "low");
    width: 100%;
    overflow: hidden;
    .labels-container {
      transition: 0.3s all ease-in-out;
      opacity: 0;
      white-space: nowrap;
      height: $toolbar-height;
    }
    .labels-item {
      display: inline-block;
    }
    &-open {
      top: $toolbar-height;
      z-index: map_get($z-index, "high");
      .labels-container {
        opacity: 1;
        height: 150px;
      }
    }
    .r-btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &.menu-open {
    .menu-user {
      width: 100vw;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .hover-div {
      width: calc(100% - #{$menu-width});
      max-width: calc(100% - #{$menu-width});
      min-height: calc(100vh - #{$toolbar-height});
      transition: 0.1s all ease-in-out;
      position: absolute;
      top: 0;
      z-index: 2;
      @include rtl() {
        left: 0;
      }
      @include ltr() {
        right: 0;
      }
    }

    .list {
      @include rtl() {
        right: 0 !important;
      }
      @include ltr() {
        left: 0 !important;
      }
    }
  }

  .menu-user {
    transition: 0.3s all ease-in-out;
    height: calc(100vh - #{$toolbar-height});
    top: $toolbar-height;
    position: fixed;
    z-index: map_get($z-index, "medium");
    opacity: 0;
    @include rtl() {
      right: 0;
    }
    @include ltr() {
      left: 0;
    }

    .list {
      overflow-y: auto;
      width: $menu-width;
      transition: 0.3s all ease-in-out;
      background-color: var(--color-sidebar-light);

      height: 100%;
      position: absolute;
      top: 0;
      @include rtl() {
        right: -$menu-width;
      }
      @include ltr() {
        left: -$menu-width;
      }
    }
  }

  .router-div {
    width: 100%;
    max-width: 100%;
    margin-top: $toolbar-height;
  }

  @include media-breakpoint-up("lg") {
    .btn-menu {
      display: none;
    }
    .menu-user {
      width: $menu-width !important;
      background-color: transparent;
      opacity: 1;
    }
    .router-div {
      width: calc(100% - #{$menu-width});
      max-width: calc(100% - #{$menu-width});
    }
    .hover-div {
      width: 0 !important;
    }
    .list {
      @include rtl() {
        right: 0 !important;
      }
      @include ltr() {
        left: 0 !important;
      }
    }
  }
}
</style>
