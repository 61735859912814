<template>
  <div class="template-home">
    <header class="home-header">
      <div class="nav-top mt-2">
        <r-row class="h-space-between no-gutters v-end">
          <r-col class="col-12 sm-auto text-center">
            <router-link :to="{ name: 'base' }">
              <img
                src="/storage/img/site/logo.png?w=200&h=60"
                alt="mallimin logo"
              />
            </router-link>
          </r-col>
          <r-col class="col-12 sm-auto">
            <r-container class="pa-0">
              <r-row class="no-gutters v-baseline">
                <r-col class="col-12 sm-auto text-center btn-info">
                  <r-btn
                    outlined
                    v-if="!$r.store.user.login"
                    :to="{ name: 'login', params: { login_first: 'password' } }"
                    class="me-2"
                    >{{ $t("login") }}
                  </r-btn>
                  <r-btn
                    outlined
                    v-else
                    :to="{ name: 'dashboard' }"
                    class="me-2"
                    >{{ $t("dashboard") }}
                  </r-btn>
                </r-col>
              </r-row>
            </r-container>
          </r-col>
        </r-row>
      </div>
    </header>
    <div class="router-div flex-grow-1 ">
      <router-view v-slot="{ Component }">
        <transition :name="$r.rtl ? 'page-slide-left' : 'page-slide-right'">
          <keep-alive :exclude="['populars', 'singleBlog']">
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </div>
    <home-footer></home-footer>
    <r-modal v-model="show_langs">
      <r-card class="pa-5">
        <r-btn
          text
          class="mb-2 color-info-text d-flex h-start"
          v-for="(i, l) in $r.store.langs_list"
          :key="l"
          @click.prevent="goLang(l)"
        >
          {{ $t(l) }}
        </r-btn>
      </r-card>
    </r-modal>
    <r-modal
      full-width
      class="px-2 px-md-10 px-lg-12"
      :closebtn="false"
      :model-value="$r.store.showCart || false"
    >
      <order-card show-action></order-card>
    </r-modal>
    <r-modal
      full-width
      class="px-2 px-md-10 px-lg-12"
      :closebtn="false"
      :model-value="$r.store.showCartVoucher || false"
    >
      <order-card-voucher show-action></order-card-voucher>
    </r-modal>
    <r-modal
      full-width
      class="px-2 px-md-10 px-lg-12"
      :closebtn="false"
      :model-value="$r.store.showCartFree || false"
    >
      <order-card-free show-action></order-card-free>
    </r-modal>
  </div>
</template>

<script>
import sideBar from "../components/sideBar";
import HomeFooter from "../components/homeFooter";
import OrderCard from "../components/orderCard";
import OrderCardVoucher from "../components/orderCardVoucher";
import OrderCardFree from "../components/orderCardFree";

export default {
  name: "admin",
  components: {
    OrderCardFree,
    OrderCardVoucher,
    OrderCard,
    HomeFooter,
    sideBar
  },
  metaInfo: {
    title: "admin"
  },
  data() {
    return {
      show_langs: false,
      open: false,
      open_menu: null
    };
  },
  created() {
    this.$r.store.voucher = 0;
    this.$r.store.categories = [];
    this.$r.store.voucher_categories = [];
    this.getMenus();
    this.get_vouchers();
    this.$r.store.cart = this.$storage.has("carts")
      ? this.$storage.get("carts")
      : {};

    this.$r.store.freeCart = this.$storage.has("freeCarts")
      ? this.$storage.get("freeCarts")
      : {};

    this.$r.store.cart_voucher = this.$storage.has("cartsVoucher")
      ? this.$storage.get("cartsVoucher")
      : {};
    this.$r.store.popular = this.$storage.has("popular")
      ? this.$storage.get("popular")
      : [];
  },
  methods: {
    goLang(name) {
      this.show_langs = false;
      const l = this.$r.store.langs_list[name];
      if (l) {
        this.$r.store.index_lang_loaded = false;
        this.$storage.set("lang", name);
        this.$storage.set("rtl", l.rtl);
        this.$r.rtl = l.rtl;
        this.$r.lang = name;
        this.$translate.loads(["renusify", "index"]);
      }
    },
    get_vouchers() {
      if (this.$storage.has("auth.token")) {
        this.$axios.get("/home/user/voucher").then(({ data }) => {
          this.$r.store.voucher = data;
        });
      }
    },
    getMenus() {
      this.$axios.get("/home/categories/" + this.$r.lang).then(({ data }) => {
        let d = [];
        data.forEach(item => {
          d.push({
            _id: item._id,
            images: item.images,
            name: item["name_" + this.$r.lang],
            desc: item["desc_" + this.$r.lang]
          });
        });
        this.$r.store.categories = d;
      });
      this.$axios
        .get("/home/vouchersCategories/" + this.$r.lang)
        .then(({ data }) => {
          let d = [];
          data.forEach(item => {
            d.push({
              _id: item._id,
              images: item.images,
              name: item["name_" + this.$r.lang],
              desc: item["desc_" + this.$r.lang]
            });
          });
          this.$r.store.voucher_categories = d;
        });
    }
  },
  watch: {
    $route: function() {
      this.open = false;
      this.open_menu = null;
    }
  }
};
</script>

<style lang="scss">
@import "~renusify/style/_include";

.template-home {
  display: flex;
  flex-direction: column;
  position: relative;

  .color-gray {
    background-color: #fbfafa;
  }

  .btn-call {
    background-color: #2da312;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 5;

    i {
      font-size: 40px !important;
    }
  }

  .router-div {
    min-height: 80vh;
    width: 100%;
    max-width: 100%;
    position: relative;
  }

  .home-header {
    background-color: var(--color-one-light);
    top: 0;
    left: 0;
    right: 0;
    position: relative;

    .nav-top {
      min-height: 85px;
      display: flex;
      align-items: flex-end;
      padding: 8px;

      a {
        font-size: 12px;
        line-height: 2.19;
        font-weight: lighter;
        color: white;
      }

      .btn-info a {
        font-size: 14px;
        font-weight: bold;
      }
    }

    img {
      width: 200px;
      height: 60px;
    }

    .language-select {
      color: var(--color-two-light) !important;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }
    }

    .local-select {
      background-color: var(--color-three-light);
      border-radius: 100px;
      cursor: pointer;
      color: var(--color-two-light);
    }
  }

  .nav-header {
    background-color: #ddd;

    .menu-list,
    .menu-title {
      padding-top: 4px;
      padding-bottom: 2px;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .menu-item {
      padding: 4px 8px;
    }

    .dropdown {
      position: relative;

      .dropdown-content {
        display: none;
      }

      .dropbtn:hover {
        cursor: pointer;
      }
    }

    &:not(.menu-mobile) .dropdown {
      &:hover {
        .dropdown-content {
          display: block;
        }
      }

      .dropdown-content {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        a {
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block;
        }
      }
    }

    &.menu-mobile {
      .menu-list:not(.open) {
        max-height: 0;
      }

      .menu-list {
        overflow: hidden;
        transition: 0.3s ease-in-out;

        &.open {
          max-height: 100vh;
        }

        .menu-item {
          background-color: white;
          color: #444444;
          border-bottom: 1px solid #d7d4d4;
          padding: 8px;

          &.open {
            .dropdown-content {
              display: block;
            }
          }
        }

        .dropdown-content {
          a {
            display: block;
            border-bottom: 1px solid #d7d4d4;
            padding: 8px;

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }

      .dropbtn {
        display: flex;
        justify-content: space-between !important;
      }
    }
  }
}
</style>
